//const API_ENDPOINT = 'http://kloo-dev-lb-1281512179.ap-south-1.elb.amazonaws.com/api/v1';
// const API_ENDPOINT = 'http://localhost:9000/api';
// const API_ENDPOINT = 'http://passport-app.test';

import { getItem, LOCAL_STORAGE_KEY } from "../../utils";

export const PrimaryURL = process.env.REACT_APP_BASE_URL;
export const Base_URL = process.env.REACT_APP_URL;
export const API_ENDPOINT = `${Base_URL}/v1`;
let expenseFilterData = "";

const isLoginViaSSO = getItem(LOCAL_STORAGE_KEY.SSO);
export const SSO_CLIENT_ID = "96d2d201-a03c-4565-9064-d9df91391233";
export const SSO_CLIENT_SECRET = "38W8vJNR3oOIRPiLnDBMRAyyXtIpogOjbQ2KbVjq";

export const CLIENT_ID = isLoginViaSSO
  ? SSO_CLIENT_ID
  : "96d2d201-a03c-4565-9064-d9df9139eaf0";
export const CLIENT_SECRET = isLoginViaSSO
  ? SSO_CLIENT_SECRET
  : "38W8vJNR3oOIRPiLnDBMRAyyXtIpogOjbQ2KbVjp";

export const MICROSOFT_CLIENT_ID = "1fcea562-6aba-4ba1-94b6-9b134e597065";
export const MICROSOFT_TENANT_ID = "1d7989eb-c8e3-42dd-9709-1f1c69df5b19";

export const ORGID = () => localStorage.getItem("org_id");
let cardVisibility = "";
export const PATH = {
  auth: {
    login: `${API_ENDPOINT}/oauth/token`,
    checkTwoFactor: `${API_ENDPOINT}/users/check-two-factor-authentication`,
    getRecoveryCodes: `${API_ENDPOINT}/users/get-recovery-codes`,
    verifyTOTP: `${API_ENDPOINT}/users/verify/two-factor-authentication`,
    verifyRecoveryCodes: `${API_ENDPOINT}/users/verify-recovery-codes`,
    refresh_login: `${API_ENDPOINT}/oauth/token/refresh`,
    token_revoke: `${API_ENDPOINT}/oauth/token/revoke`,
    sso_login: `${API_ENDPOINT}/oauth/token-without-password-sso`,
    // sanctionCheck: (id, birthDate, cc, contact, password) => `${API_ENDPOINT}/users/${id}/sanction-check/birth-date/${birthDate}/country-code/${cc}/contact/${contact}/password/${password}`,
    sanctionCheck: (id) =>
      `${API_ENDPOINT}/users/${id}/sanction-check${
        window.location.search ? window.location.search : ""
      }`,
    selfRegistration: (id) => `${API_ENDPOINT}/users/${id}/self-registration`,
    verifyRegOTP: () => `${API_ENDPOINT}/users/verify-otp-via-mobile`,
    verifyOTPViaMobile: `${API_ENDPOINT}/users/verify-otp-via-mobile`,
    forgotPasswordVerify: (email) =>
      `${API_ENDPOINT}/users/forget-password/verify/${email}`,
    forgotPassword: (email) =>
      `${API_ENDPOINT}/users/forget-password/reset/${email}`,
    changePassword: (id) => `${API_ENDPOINT}/users/${id}/password`,
    updateTeamMember: (id) => `${API_ENDPOINT}/teams/${id}`,
    deleteTeamMember: (id) => `${API_ENDPOINT}/teams/${id}`,
    createNewUser: `${API_ENDPOINT}/users/create_user`,
    UpdateUser: (id) => `${API_ENDPOINT}/users/${id}`,
    DeleteUser: (id) => `${API_ENDPOINT}/users/${id}`,
    GetUser: (id) => `${API_ENDPOINT}/users/${id}`,
    verifyEmail: (email) => `${API_ENDPOINT}/users/send-otp/${email}`,
    verifyEmailRegistration: (email, contact, country_code) =>
      `${API_ENDPOINT}/users/send-otp/${email}?contact=${contact}&country_code=${country_code}`,
    verifyOtp: (userId) =>
      `${API_ENDPOINT}/users/forget-password/${userId}/verify-otp`,
    verifyCardOtp: (userId) => `${API_ENDPOINT}/users/${userId}/verify-otp`,
    RegistrationAnalytics: (invite) =>
      `${API_ENDPOINT}/organizations/analytics/validate/registration${
        invite && "?type=invited"
      }`,
    analyticsRegistor: `${API_ENDPOINT}/organizations/analytics/validate/registration?type=invited`,
    UserAnalytics: () => `${API_ENDPOINT}/organizations/analytics/user`,
    updateUserAnalytics: (userOrgId) =>
      `${API_ENDPOINT}/organizations/analytics/user-registration/${userOrgId}`,
    RegistrationLink: (userOrgId) =>
      `${API_ENDPOINT}/organizations/analytics/user-details/${userOrgId}`,
    verifyOtpAnalytics: () =>
      `${API_ENDPOINT}/users/analytics/verify-otp-analytics`,
    lastLoginUpdate: (userOrgId) =>
      `${API_ENDPOINT}/organizations/${userOrgId}/last-login-update`,
    otpRequirment: () => `${API_ENDPOINT}/oauth/get-environment-scope`,
    otpRequirement: `${API_ENDPOINT}/oauth/get-environment-scope`,
    checkSSOEnabled: `${API_ENDPOINT}/users/check-sso-enabled`,
    orgData: ({ orgId }) => `${API_ENDPOINT}/organizations/org-data/${orgId}`,
    updateOrgData: ({ orgId }) =>
      `${API_ENDPOINT}/organizations/${orgId}/update-default-org-value`,
  },
  transactions: {
    getAll: () =>
      `${API_ENDPOINT}/organizations/transactions/recent-transactions/${ORGID()}`,
    monthlyTransaction: (orgId, userId) =>
      `${API_ENDPOINT}/organizations/transactions/monthly-transactions/${ORGID()}/${userId}`,
    monthWiseTransaction: (orgId, userId, start_date, end_date) =>
      `${API_ENDPOINT}/organizations/transactions/month-wise-spend/organisation/${ORGID()}/user/${userId}/${start_date}/${end_date}`,
    getSpent: (orgId, userId, start_date, end_date) =>
      `${API_ENDPOINT}/organizations/transactions/get-total-spend/organisation/${ORGID()}/user/${userId}/${start_date}/${end_date}`,
  },
  user: {
    getFilterListAllCard: (orgID) =>
      `${API_ENDPOINT}/cards/getFilterList/${orgID}`,
    getFilterListMyCard: (orgId, userOrgId) =>
      `${API_ENDPOINT}/cards/getFilterList/${orgId}?user_org_id=${userOrgId}`,
    GetUser: (team, page, status, role, filterUrl = "") =>
      `${API_ENDPOINT}/organizations/${ORGID()}/users?services=${
        team ? "team" : ""
      }${team && role ? "," : ""}${
        role ? "role" : ""
      }&status=${status}&page=${page}&filter=${filterUrl}`,
    createNewUser: (type) =>
      `${API_ENDPOINT}/organizations/${ORGID()}/users?type=${type}`,
    UpdateUser: (id) => `${API_ENDPOINT}/organizations/${ORGID()}/users/${id}`,
    DeleteUser: (id) => `${API_ENDPOINT}/organizations/${ORGID()}/users/${id}`,
    invitationLink: (id, type) =>
      `${API_ENDPOINT}/organizations/${ORGID()}/users/${id}/invitation-link?type=${type}`,
    disableUser: (id) =>
      `${API_ENDPOINT}/organizations/${ORGID()}/users/${id}/status/disable`,
    activeUser: (id) =>
      `${API_ENDPOINT}/organizations/${ORGID()}/users/${id}/status/active`,
    resendInvite: (id) =>
      `${API_ENDPOINT}/organizations/${ORGID()}/users/${id}/status/invited`,
    removeInvite: (id) =>
      `${API_ENDPOINT}/organizations/${ORGID()}/users/${id}/status/inactive`,
    createUserAddress: (id) => `${API_ENDPOINT}/organizations/${id}/address`,
    getNotification: (orgId, userId) =>
      `${API_ENDPOINT}/organizations/notifications/${ORGID()}/${userId}`,
    UpdateUserProfile: (userId, analytics) =>
      `${API_ENDPOINT}/users/${analytics ? "analytics/" : ""}${userId}/profile`,
    UserProfileUpdate: (email, CountryCode, Contact) =>
      `${API_ENDPOINT}/users/send-otp/${email}?country_code=${CountryCode}&contact=${Contact}`,
    UserProfileVerify: (userId, CountryCode, Phone) =>
      `${API_ENDPOINT}/users/${userId}/verify-otp?country_code=${CountryCode}&phone=${Phone}`,
    OrganisationAmount: (org_id) =>
      `${API_ENDPOINT}/organizations/${org_id}/amount`,
    downloadUser: (page, userUrl) =>
      `${API_ENDPOINT}/organizations/${ORGID()}/users?services=role,team&is_download=${true}&page=${page}${userUrl}`,
    usersFilter: (OrgId, PageNo, userFilterUrl) =>
      `${API_ENDPOINT}/organizations/${ORGID()}/users?services=team,role&page=${PageNo}${userFilterUrl}`,
    filterType: (userFilterUrl) =>
      `${API_ENDPOINT}/organizations/getFilterList/${ORGID()}`,
    mobileModularUpdate: (userOrgId) =>
      `${API_ENDPOINT}/cards/user-mobile-update-on-modulr/${userOrgId}`,
    userListForSelect: (orgId, status = null) =>
      `${API_ENDPOINT}/organizations/${orgId}/users/select-dropdown${
        status ? `?status=${status}` : ""
      }`,
    poRegistration: () => `${API_ENDPOINT}/users/org-others-user-pwd`,
    getDOBRequirement: `${API_ENDPOINT}/users/for-yapily/dob`,
    getPendingRequest: (userOrgId, teamId) =>
      `${API_ENDPOINT}/teams/${teamId}/userId/${userOrgId}/get-team-wise-user-pending-request`,
  },
  teams: {
    getAllTeams: `${API_ENDPOINT}/teams/`,
    createTeam: `${API_ENDPOINT}/teams`,
    getNotInTeamMembersList: `${API_ENDPOINT}/teams/member-not-in-team`,
    getSelectedTeamMemberList: (teamId, orgId) =>
      `${API_ENDPOINT}/teams/${teamId}/member/${orgId}/deassign`,
    getUserMemeberListForDropDown: (id) =>
      `${API_ENDPOINT}/teams/${id}/add-team-members-list`,
    getUserManagerListForDropDown: (id) =>
      `${API_ENDPOINT}/teams/${id}/add-team-managers-list`,
    getCurrentMembersList: (teamId) =>
      `${API_ENDPOINT}/teams/${teamId}/team-wise-members-list`,
    getCurrentManagerList: (teamId) =>
      `${API_ENDPOINT}/teams/${teamId}/team-wise-managers-list`,
    getNotTeamMemberList: (teamId) =>
      `${API_ENDPOINT}/teams/member-not-in-team`,
    assignUserToTeam: (team_id, user_org_id) =>
      `${API_ENDPOINT}/teams/${team_id}/member/${user_org_id}/assign`,
    addNewMember: (id, userId) =>
      `${API_ENDPOINT}/teams/${id}/member/${userId}/assign`,
    addNewManager: (team_id, user_org_id) =>
      `${API_ENDPOINT}/teams/${team_id}/manager/${user_org_id}/assign`,
    unassignMember: (teamId, userId) =>
      `${API_ENDPOINT}/teams/${teamId}/member/${userId}/deassign`,
    unassignManager: (teamId, userId) =>
      `${API_ENDPOINT}/teams/${teamId}/manager/${userId}/deassign`,
    updateUserToTeam: (team_id, user_org_id) =>
      `${API_ENDPOINT}/teams/${team_id}/userId/${user_org_id}/update-user-team`,
    getAllRoles: (orgId) =>
      `${API_ENDPOINT}/roles/roleList/organization/${ORGID()}`,
    assignUserToRole: (roleId, userId, organization) =>
      `${API_ENDPOINT}/roles/${roleId}/users/${userId}`,
    createAssignUserToRole: (userOrgId) =>
      `${API_ENDPOINT}/roles/organization/${userOrgId}`,
    getAllTeamsList: `${API_ENDPOINT}/teams/all-teams`,
    getTeamByID: (id) => `${API_ENDPOINT}/teams/${id}`,
    getNastedTeam: `${API_ENDPOINT}/teams/nested-teams-list`,
    getUserRole: (userOrgId) => `${API_ENDPOINT}/roles/user-role/${userOrgId}`,
    teamStatus: (team_id, status) =>
      `${API_ENDPOINT}/teams/${team_id}/status/${status}`,
  },
  cards: {
    createVirtual: `${API_ENDPOINT}/cards/virtual`,
    createPhysical: `${API_ENDPOINT}/cards/physical`,
    createVirtualPU: `${API_ENDPOINT}/cards/virtual/create`,
    createPhysicalPU: `${API_ENDPOINT}/cards/physical/create`,
    getCards: (page) => `${API_ENDPOINT}/cards/${ORGID()}?page=${page}`,
    getCardsDetails: (id) => `${API_ENDPOINT}/cards/getCardDetails/${id}`,
    search: (search) => `${API_ENDPOINT}/cards/${ORGID()}?search=${search}`,
    editCardLimit: (id) => `${API_ENDPOINT}/cards/${id}/monthlyAmt`,
    editCardNickName: (id) => `${API_ENDPOINT}/cards/${id}/nickname`,
    cancelCard: (cardId) => `${API_ENDPOINT}/cards/${cardId}/cancel`,
    blockCard: (cardId) => `${API_ENDPOINT}/cards/${cardId}/block`,
    unblockCard: (cardId) => `${API_ENDPOINT}/cards/${cardId}/unblock`,
    getTransactionList: `${API_ENDPOINT}/transactions/recent-transactions`,
    GetCustomerBilling: (id) => `${API_ENDPOINT}/customers/${id}`,
    CardList: `${API_ENDPOINT}/cards`,
    ApproveCard: (id) => `${API_ENDPOINT}/cards/${id}`,
    BlockCard: (id) => `${API_ENDPOINT}/cards/${id}/block`,
    UnblockCard: (id) => `${API_ENDPOINT}/cards/${id}/unblock`,
    DeleteCard: (id) => `${API_ENDPOINT}/cards/${id}`,
    DeclineCard: (id) => `${API_ENDPOINT}/cards/requests/${id}`,
    EditCardLimit: (id) => `${API_ENDPOINT}/cards/${id}/cardlimit`,
    CancelCardReq: (id) => `${API_ENDPOINT}/cards/${id}/cancel`,
    getUserCards: (OrgId, page, userOrgId) =>
      `${API_ENDPOINT}/cards/${OrgId}?page=${page}&user_org_id=${userOrgId}`,
    getSearchUserCards: (search, userOrgId) =>
      `${API_ENDPOINT}/cards/getUserCards/${userOrgId}?search=${search}`,
    activatePhysicalCard: (id) =>
      `${API_ENDPOINT}/cards/activatePhysicalCard/${id}`,
    retrievePin: (id) => `${API_ENDPOINT}/cards/retrievePin/${id}`,
    settingsWorkflows: `${API_ENDPOINT}/smart-approval/settingsWorkflows`,
    approvalSettings: (org_id) =>
      `${API_ENDPOINT}/smart-approval/getApprovalSettings/${org_id}`,
    settingsWork: (org_id) =>
      `${API_ENDPOINT}/workspace/Team-Workspace~${org_id}/request/${org_id}`,
    pendingCard: (userOrgId, page) =>
      `${API_ENDPOINT}/cards/pending/${userOrgId}?page=${page}`,
    approvedCard: (id) => `${API_ENDPOINT}/cards/approve/${id}`,
    approveCancelCard: (id) => `${API_ENDPOINT}/cards/${id}/cancel`,
    CardDownload: (org_id, pageNo, urlFilter) =>
      `${API_ENDPOINT}/cards/download/${org_id}?page=${pageNo}${urlFilter}`,
    // myCardDownload:(org_id) => `${API_ENDPOINT}/cards/download-mycards/${org_id}`,
    unmaskCardNumber: (id) => `${API_ENDPOINT}/cards/show_masking/${id}`,
    deleteCard: (id) => `${API_ENDPOINT}/cards/delete/${id}`,
    CardsSearchFilter: (orgId, page, filterUrl) =>
      `${API_ENDPOINT}/cards/${orgId}?page=${page}${filterUrl}`,
    accountDetails: (orgId) =>
      `${API_ENDPOINT}/organizations/${orgId}/modulr-accounts-details`,
    cardsListing: `${API_ENDPOINT}/cards`,
    cardsListingDownload: `${API_ENDPOINT}/cards/download`,
  },
  accountPayable: {
    getlist: (status, page, extraParams = "") =>
      `${API_ENDPOINT}/ap/list?status=${status}&page=${page}${extraParams}`,
    getSuppliers: (searchURL = "", entityId = "") =>
      `${API_ENDPOINT}/ap/suppliers${searchURL}${
        entityId ? `?entity_id=${entityId}` : ""
      }`,
    getCountriesList: `${API_ENDPOINT}/users/masters/countries`,
    getCurrencyList: `${API_ENDPOINT}/users/masters/currencies`,
    createPayment: `${API_ENDPOINT}/ap`,
    createDraftInvoice: `${API_ENDPOINT}/ap/draft/`,
    updateDraftInvoice: ({ id }) =>
      `${API_ENDPOINT}/ap/draft/${id}?_method=PUT`,
    deleteDraftAttachment: ({ id }) =>
      `${API_ENDPOINT}/ap/invoice-attachment/${id}`,
    ocrReceiptsUpload: `${API_ENDPOINT}/ap/ocr-receipts-upload`,
    assignSuppliers: `${API_ENDPOINT}/ap/suppliers`,
    updatePayment: (id) => `${API_ENDPOINT}/ap/${id}?_method=PUT`,
    beneficiaries: `${API_ENDPOINT}/ap/beneficiaries`,
    invoicePayment: `${API_ENDPOINT}/ap/invoice-payment`,
    reschedulePayment: `${API_ENDPOINT}/ap/reschedule-invoice-payment`,
    rescheduleCanceldelete: (id, statusKey) =>
      `${API_ENDPOINT}/ap/${id}/status/${statusKey}`,
    // getServiceDurationUnitList: `${API_ENDPOINT}/users/masters/currencies`,
    getXeroCategoryList: (page_name) =>
      `${API_ENDPOINT}/accounting-integrations/kloo/datasets/categories/list${
        page_name ? `?page_name=${page_name}` : ""
      }`,
    approvePayment: (id) => `${API_ENDPOINT}/ap/${id}/status/approved`,
    rejectPayment: (id) => `${API_ENDPOINT}/ap/${id}/status/rejected`,
    checkPaymentOption: `${API_ENDPOINT}/smart-approval/createWorkflowActivity`,
    downloadAccountPayList: (
      statustab,
      accountPayablePageNo,
      accountPayableUrl
    ) =>
      `${API_ENDPOINT}/ap/download?status=${statustab}&page=${accountPayablePageNo}${accountPayableUrl}`,
    accountPayableFilter: (accountStatus, page, AccountUrl) =>
      `${API_ENDPOINT}/ap/list?status=${accountStatus}&page=${page}${AccountUrl}`,
    getFilterAccountList: (tabStatus) =>
      `${API_ENDPOINT}/ap/getFilterList?status=${tabStatus}`,
    apFiledelete: (id) => `${API_ENDPOINT}/ap/deleteFile/${id}`,
    markAsFasterPayment: (invoiceId) =>
      `${API_ENDPOINT}/ap/invoice-mark-as-paid/${invoiceId}`,
    approveNowPayment: (invoiceId) =>
      `${API_ENDPOINT}/ap/approve-now/${invoiceId}`,
    resubmitRejectedInvoice: (id) => `${API_ENDPOINT}/ap/resubmit/${id}`,
    getAuditLogByInvoiceId: (id) =>
      `${API_ENDPOINT}/ap/audits-invoice-history/${id}`,
    validatePayment: `${API_ENDPOINT}/ap/validation-open-banking`,
    customFieldByAi: `${API_ENDPOINT}/ap/custom-field-ai-suggestions`,
    bulkAction: `${API_ENDPOINT}/ap/bulk-actions`,
    postComment: `${API_ENDPOINT}/ap/audit-log-comments`,
    getAiTriggers: `${API_ENDPOINT}/ap/get-customfield-ai-suggestions-triggers`,
    invoiceOCRLineItemDetails: (id) =>
      `${API_ENDPOINT}/ap/${id}/invoice-ocr-line-item-details`,
    getInvoiceDetailsById: (id, status) => `${Base_URL}/v2/ap/${id}/${status}`,
    getTaxRatesByEntity: (entityId) =>
      `${API_ENDPOINT}/organizations/erp-accounts/${entityId}`,
    validateTaxSolution: `${API_ENDPOINT}/organizations/validate-values`,
  },
  supplierManagement: {
    listing: `${API_ENDPOINT}/ap/suppliers`,
    create: `${API_ENDPOINT}/ap/suppliers-with-address`,
    addAccounts: `${API_ENDPOINT}/ap/suppliers-acc-with-bank`,
    edit: (supplierId) => `${API_ENDPOINT}/ap/suppliers/update/${supplierId}`,
    delete: (supplierId) => `${API_ENDPOINT}/ap/suppliers/${supplierId}`,
    deleteSupplierAccount: (accountId) =>
      `${API_ENDPOINT}/ap/suppliers/accId/${accountId}`,
    smartApproval: {
      createEditPermission: {
        create: `${Base_URL}/v4/smart-approval/create-access`,
        listing: `${Base_URL}/v4/smart-approval/get-access?module=Payee Management`,
      },
      checkPermission: `${Base_URL}/v4/smart-approval/check-access?module=Payee Management`,
    },
    isSupplierAssociatedToInvoice: (supplierId) =>
      `${API_ENDPOINT}/ap/workflow/suppliers/${supplierId}`,
    isSupplierAccAssociatedToInvoice: (accountId) =>
      `${API_ENDPOINT}/ap/workflow/suppliers/accId/${accountId}`,
    cheatSheetList: `${API_ENDPOINT}/organizations/cheatSheet`,
  },
  currency: {
    listCurrencies: `${API_ENDPOINT}/users/masters/currencies`,
  },
  modular: {
    accountDetails: (orgId) =>
      `${API_ENDPOINT}/organizations/${orgId}/modulr-accounts-details`,
  },
  cardExpenses: {
    getExpensesList: (status, page, visibility) => {
      cardVisibility = visibility;
      return `${API_ENDPOINT}/card-expenses?visibility=${visibility}&status=${status}&page=${page}${expenseFilterData}`;
    },
    getExpensesFilterList: (status) =>
      `${API_ENDPOINT}/card-expenses/getFilterList?status=${status}&visibility=${cardVisibility}`,
    markForReview: `${API_ENDPOINT}/card-expenses/mark-review`,
    saveAsDraft: (id) =>
      `${API_ENDPOINT}/card-expenses/mark-review-draft/${id}?_method=PUT`,
    archiveExpenses: `${API_ENDPOINT}/card-expenses/archive`,
    markAsApproved: (reviewId) =>
      `${API_ENDPOINT}/card-expenses/approve-expenses/${reviewId}`,
    markExpenseAsApproved: `${API_ENDPOINT}/card-expenses/approve-expenses`,
    // getXeroCustomerList: `${API_ENDPOINT}/accounting-integrations/datasets/chartOfAccounts`,
    getXeroCustomerContactIDList: `${API_ENDPOINT}/accounting-integrations/datasets/customers`,
    expenseFileUpload: `${API_ENDPOINT}/card-expenses/file-upload`,
    expenseFileDelete: (id) => `${API_ENDPOINT}/card-expenses/deleteFile/${id}`,
    getVatRateList: `${API_ENDPOINT}/accounting-integrations/datasets/taxRates`,
    // downloadExpenseList: (status) => `${API_ENDPOINT}/card-expenses/download?status=${status}`,
    cardExpensesFilter: (status, page, expensesFilterUrl) => {
      expenseFilterData = expensesFilterUrl;
      return `${API_ENDPOINT}/card-expenses?status=${status}&page=${page}${expensesFilterUrl}&visibility=${cardVisibility}`;
    },
    downloadExpenseList: (status, page, expensesFilterUrl) =>
      `${API_ENDPOINT}/card-expenses/download?status=${status}&page=${page}${expensesFilterUrl}&visibility=${cardVisibility}`,
    saveOutstandingAsDraft: `${API_ENDPOINT}/card-expenses/save-outstanding`,
    cardsExpenseListing: `${API_ENDPOINT}/card-expenses`,
    cardsExpensesListingDownload: `${API_ENDPOINT}/card-expenses/download`,
  },
  invoiceManagement: {
    listing: `${API_ENDPOINT}/ap/list`,
    download: `${API_ENDPOINT}/ap/download`,
    delete: (id) => `${API_ENDPOINT}/ap/delete-invoice/${id}`,
    changeStatus: (id, status) => `${API_ENDPOINT}/ap/${id}/status/${status}`,
    getCustomExpenseValues: (id) => `${API_ENDPOINT}/ap/invoices-details/${id}`,
  },
  invoiceMatching: {
    manualListing: `${API_ENDPOINT}/purchase-orders/invoice-po-list`,
    splitListing: `${API_ENDPOINT}/purchase-orders/invoice-match-po-list`,
  },
  codat: {
    codatDetails: `${API_ENDPOINT}/accounting-integrations/companies`,
    // getCodatStatus : `${API_ENDPOINT}/users/masters/currencies`,
    syncData: `${API_ENDPOINT}/accounting-integrations/datasets/allCodatSyncable/queue`,
    setupDone: `${API_ENDPOINT}/accounting-integrations/companies/setup-done`,
    setupDoneRefresh: `${API_ENDPOINT}/accounting-integrations/companies/link`,
    authorization_redirect_link: `${API_ENDPOINT}/accounting-integrations/companies/authorization-redirect-url`,
  },
  menu: {
    menuList: (orgId) => `${API_ENDPOINT}/roles/user-role-scopes-ui/${orgId}`,
  },
  categories: {
    categories: (type) =>
      `${API_ENDPOINT}/accounting-integrations/datasets/${type}`,
    categoriesAll: (page_name) =>
      `${API_ENDPOINT}/accounting-integrations/kloo/datasets/categories/list${
        page_name ? `?page_name=${page_name}` : ""
      }`,
    createCategory: (page_name) =>
      `${API_ENDPOINT}/accounting-integrations/kloo/datasets/categories/create${
        page_name ? `?page_name=${page_name}` : ""
      }`,
    updateCategory: (page_name, change_col_status = false) =>
      `${API_ENDPOINT}/accounting-integrations/kloo/datasets/categories/update${
        page_name ? `?page_name=${page_name}` : ""
      }${change_col_status ? `&change_col_status=${change_col_status}` : ""}`,
    deleteCategory: (id) =>
      `${API_ENDPOINT}/accounting-integrations/kloo/datasets/categories/update/status/${id}`,
  },
  smart_approval: {
    settingsWorkflows: (type) => `${API_ENDPOINT}/smart-approval/${type}`,
    teamManagerList: `${API_ENDPOINT}/smart-approval/team-manager-attribute`,
    checkSettingsWorkflows: `${API_ENDPOINT}/smart-approval/checkSettingsWorkflows`,
    smartApprovalGETListing: `${Base_URL}/v2/smart-approval`,
    smartApproveCreateListing: `${Base_URL}/v2/smart-approval`,
    smartApproveUpdateListing: `${Base_URL}/v3/smart-approval`,
    getSmartApprovalAuditHistory: `${Base_URL}/v2/smart-approval/audit-smart-approval-history`,
    smartApprovalworkFlowList: (page, searchUrl) =>
      `${Base_URL}/v3/smart-approval/get-workflow-list?page=${page}${searchUrl}`,
    smartApprovalworkFlowListV1: (page, searchUrl, type) =>
      `${Base_URL}/v3/smart-approval/get-all-workflows?type=${type}${searchUrl}`,
    getAllWorkFlows: (type) =>
      `${Base_URL}/v3/smart-approval/get-all-workflows?type=${type}`,
    assignWorkflow: `${Base_URL}/v1/ap/assign-workflow`,
    assignWorkFlowToInvoicePo: `${Base_URL}/v3/smart-approval/assign-workflow`,
    assignWorkFlowLambdaApi: (urlParams) =>
      `${PrimaryURL}${urlParams}?workflow_type=assign_workflow`,
    reassignWorkFlowLambdaApi: () =>
      `${Base_URL}/v3/smart-approval/reassign/workflow`,
    unassignWorkFlowLambdaApi: () =>
      `${Base_URL}/v3/smart-approval/unassign/workflow`,
    deleteWorkFlowV1: (id, type) =>
      `${Base_URL}/v3/smart-approval/delete-workflow/${id}/${type}`,
    deleteWorkFlow: (id) =>
      `${Base_URL}/v3/smart-approval/delete-workflow/${id}`,
    getSelectedWorkFlowDetail: (id, type) =>
      `${Base_URL}/v3/smart-approval/event-details-by-workflow/${id}/${type}`,
    checkSelectedWorkFlowDetail: (id, type) =>
      `${Base_URL}/v3/smart-approval/delete-workflow/${id}/${type}/check`,
    teamManagerAndApList: `${Base_URL}/v1/smart-approval/team-manager-and-ap-attribute`,
    invoiceAutomationList: `${Base_URL}/v1/smart-approval/automations/fields/invoice`,
    automationToggleStatusList: `${Base_URL}/v1/smart-approval/automations`,
    saveAutomationMapping: (type) =>
      `${Base_URL}/v1/smart-approval/automations/mappers/${type}`,
    getMappedAutomation: (type) =>
      `${Base_URL}/v1/smart-approval/automations/mappers/${type}`,
    deleteMappedAutomation: (type) =>
      `${Base_URL}/v1/smart-approval/automations/mappers/${type}`,
    updateMappedAutomation: (type) =>
      `${Base_URL}/v1/smart-approval/automations/mappers/${type}`,
    getIntegrationCount: (type, id) =>
      `${Base_URL}/v1/smart-approval/automations/integrations-associated-count?integration_type=${type}&mapping_id=${id}`,
    savePreventWorkflow: ({ type }) =>
      `${Base_URL}/v1/smart-approval/prevent-assignment/${type}`,
    getWorkflowPreventCount: ({ checkBoxPrevent }) =>
      `${Base_URL}/v1/ap/invoice-count?workflow_assignment_invoice=${checkBoxPrevent}`,
    downloadMappingListExcel: ({ type }) =>
      `${Base_URL}/v1/smart-approval/automations/download-excel/${type}`,
  },
  expense_field: {
    getListTraking: `${API_ENDPOINT}/accounting-integrations/datasets/get-nested-child/trackingCategories`,
    getValidationList: (page_name) =>
      `${API_ENDPOINT}/organizations/getValidationList/${ORGID()}${
        page_name ? `?page_name=${page_name}` : ""
      }`,
    saveTrackingList: () =>
      `${API_ENDPOINT}/organizations/create-validation/${ORGID()}`,
  },
  custom_expenses: {
    updateCustomValidation: (page_name, change_col_status = false) =>
      `${Base_URL}/${
        page_name === "purchase_order" ? "v2" : "v1"
      }/organizations/update-custom-validation${
        page_name ? `?page_name=${page_name}` : ""
      }${change_col_status ? `&change_col_status=${change_col_status}` : ""}`,
    createExpensesFileld: (page_name, apiVersion) =>
      `${Base_URL}/v2/organizations/create-custom-validation${
        page_name ? `?page_name=${page_name}` : ""
      }`,
    customExpenseGetList: (categoryType, page_name) =>
      `${API_ENDPOINT}/organizations/getValidationList/${ORGID()}?categoryType=${categoryType}${
        page_name ? `&page_name=${page_name}` : ""
      }`,
    deleteExpenseField: (id) =>
      `${API_ENDPOINT}/organizations/delete-custom-validation/${id}`,
    //   getpreDefinedList:(id)=>`${API_ENDPOINT}/organizations/predefinedlist/${id}`,
    getCustomFieldValues: (id, type) =>
      `${API_ENDPOINT}/organizations/values/${id}?type=${type}`,
    createManageCustom: (id, createUrl) =>
      `${API_ENDPOINT}/organizations/create-values/${id}${createUrl}`,
    createChildlist: (userId, parentId, type) =>
      `${API_ENDPOINT}/organizations/create-values/${userId}?parent_id=${parentId}&type=${type}`,
    createChildlistV2: (type, id) =>
      `${Base_URL}/v2/organizations/custom-fields/${type}/${id}/values`,
    deleteCustomFiled: (id) => `${API_ENDPOINT}/organizations/values/${id}`,
    UpadetePredefinePerent: (userId) =>
      `${API_ENDPOINT}/organizations/update-values/${userId}`,
    AllGetDAtaAccountCustom: (type) =>
      `${API_ENDPOINT}/organizations/update-values/${type}`,
    getCustomFields: (categoryType, pageName) =>
      `${API_ENDPOINT}/organizations/validation-list-with-values/${categoryType}/${pageName}`,
  },
  funds: {
    fundsAccounts: (org_id) =>
      `${API_ENDPOINT}/organizations/${org_id}/organizations-modulr-details`,
    updateNotifiThresholdAmount: (org_id, org_acc_id) =>
      `${API_ENDPOINT}/organizations/${org_id}/update-account-balance-notification-threshold-amount/${org_acc_id}`,
  },
  cardRequests: {
    myCardRequests: (userOrgId, page) =>
      `${API_ENDPOINT}/cards/my-approvals/${userOrgId}?page=${page}`,
    allCardRequests: (userOrgId, page) =>
      `${API_ENDPOINT}/cards/all-approvals/${userOrgId}?page=${page}`,
    listing: `${API_ENDPOINT}/cards`,
  },
  postCode: {
    checkPostCode: (postcode) =>
      `${API_ENDPOINT}/cards/checkPostcode/${postcode}`,
  },
  analyticsDashboard: {
    getKlooSpendAnalyticsDashboardToggle: () =>
      `${API_ENDPOINT}/card-expenses/toggle`,
  },
  switchProfile: {
    getOrganisationMultipleProfile: (user_id) =>
      `${API_ENDPOINT}/users/organisations/${user_id}`,
  },
  audit: {
    getAuditHistoryByCardId: (id) =>
      `${API_ENDPOINT}/cards/audit-card-history/${id}`,
    auditHistoryBySupplierId: `${API_ENDPOINT}/ap/audit-supplier-history`,
  },
  notification: {
    getNotifications: `${API_ENDPOINT}/organizations/notifications/banner-notifications`,
  },
  yapilyIntegration: {
    getInstitution: `${API_ENDPOINT}/bank-data-integration/institutions`,
    getAuthorizationToken: `${API_ENDPOINT}/bank-data-integration/consent-token`,
    storeConsentToken: `${API_ENDPOINT}/bank-data-integration/store-consent-token`,
    activeInstitution: `${API_ENDPOINT}/bank-data-integration/active-banks`,
    checkConsentStatus: (consentId) =>
      `${API_ENDPOINT}/bank-data-integration/consent-status/${consentId}`,
    renewConsentToken: `${API_ENDPOINT}/bank-data-integration/renew-consent-token`,
    reAuthorizeConsentToken: `${API_ENDPOINT}/bank-data-integration/reauthorize-consent`,
    getBankAccountsByConsent: (consentId) =>
      `${API_ENDPOINT}/bank-data-integration/connected-accounts/${consentId}`,
    withdrawConsent: (consentId) =>
      `${API_ENDPOINT}/bank-data-integration/withdrawal-consent/${consentId}`,
    getPaymentAuthorizationToken: `${API_ENDPOINT}/bank-data-integration/invoice-payments`,
    checkPaymentConsentStatus: (consentId) =>
      `${API_ENDPOINT}/bank-data-integration/get-payment-consent-status/${consentId}`,
  },
  purchaseOrder: {
    getPurchaseOrder: (id) =>
      `${API_ENDPOINT}/purchase-orders/purchase-order-detail/${id}`,
    supplierList: `${API_ENDPOINT}/purchase-orders/suppliers`,
    addSupplier: `${API_ENDPOINT}/purchase-orders/create-supplier`,
    create: (isDraft) =>
      `${API_ENDPOINT}/purchase-orders${isDraft ? "/draft" : ""}`,
    update: (orderId, isDraft) =>
      `${API_ENDPOINT}/purchase-orders/${isDraft ? "draft/" : ""}${orderId}`,
    resubmit: (orderId, isDraft) =>
      `${API_ENDPOINT}/purchase-orders/${isDraft ? "draft/" : ""}${orderId}${
        !isDraft ? "/resubmit" : ""
      }`,
    assign: `${API_ENDPOINT}/purchase-orders/assign-approval`,
    approve: (status) => `${API_ENDPOINT}/purchase-orders/status/${status}`,
    listing: ({ apiVersion }) => {
      return `${Base_URL}/${apiVersion}/purchase-orders`;
    },
    downloadPDF: (orderId) => `${API_ENDPOINT}/purchase-orders/pdf/${orderId}`,
    downloadExcel: `${API_ENDPOINT}/purchase-orders/download-excel`,
    GRNI: {
      listing: (id) => `${API_ENDPOINT}/purchase-orders/grni-list/${id}`,
      downloadExcel: (id) =>
        `${API_ENDPOINT}/purchase-orders/grni-list-download/${id}`,
      create: `${API_ENDPOINT}/purchase-orders/create-grni`,
      update: (id) => `${API_ENDPOINT}/purchase-orders/update-grni/${id}`,
      delete: (id) => `${API_ENDPOINT}/purchase-orders/delete-grni/${id}`,
    },
    addAttachments: (poId) =>
      `${API_ENDPOINT}/purchase-orders/add-attachment/${poId}`,
    removeAttachment: `${API_ENDPOINT}/purchase-orders/remove-po-attachment`,
    auditLog: `${API_ENDPOINT}/purchase-orders/purchase-order-audit-history`,
    poDropdown: `${API_ENDPOINT}/purchase-orders/all/approved`,
    sendEmailtoSupplier: `${API_ENDPOINT}/purchase-orders/emails`,
    getPurchaseOrderCreatorsList: (module) =>
      `${Base_URL}/v4/smart-approval/modulewise-users?module=${module}`,
    getFaAndPuUsers: (status = "active") =>
      `${API_ENDPOINT}/organizations/${ORGID()}/fa-pu-users?status=${status}`,
    addPurchaseRequestCreator: `${Base_URL}/v4/smart-approval/modulewise-users/`,
    removeUserFromPrCreatorList: (userId) =>
      `${Base_URL}/v4/smart-approval/modulewise-users/${userId}/status/deleted`,
    postComment: `${API_ENDPOINT}/purchase-orders/audit-log-comments`,
    getOrgAIEmail: (orgId) =>
      `${API_ENDPOINT}/organizations/automation/${orgId}/organization-wise-po-automation-email-address`,
  },

  invoice: {
    createNewWorkflow: `${Base_URL}/v3/smart-approval/create-workflow`,
    updateWorkflow: `${Base_URL}/v3/smart-approval/edit-workflow`,
    getWorkflowCategory: `${Base_URL}/v3/smart-approval/get-workflow-categories`,
    invoiceSupplierList: (integrationType) =>
      `${Base_URL}/v1/smart-approval/automations/fields/${integrationType}`,
    saveToggleAutomation: `${Base_URL}/v1/smart-approval/automations`,
    createMApping: (integration_type) =>
      `${Base_URL}/v1/smart-approval/automations/mappers/${integration_type}`,
    deleteMapping: (integration_type) =>
      `${Base_URL}/v1/smart-approval/automations/mappers/${integration_type}`,
    addMappingListing: (api) => `${Base_URL}${api}`,
    handleMappingListing: (integration_type) =>
      `${Base_URL}/v1/smart-approval/automations/mappers/${integration_type}`,
    // assignWorkflowMapping: `${Base_URL}/v1/smart-approval/automations/test`,
    reassignWorkflow: (action) =>
      `${Base_URL}/v3/smart-approval/${action}/workflow`,
    bulkAssignWorkflow: `${Base_URL}/v3/smart-approval/assign-workflow`,
    emailUpload: (orgId) =>
      `${API_ENDPOINT}/organizations/automation/${orgId}/organization-wise-automation-email-address`,
    taxSolutionDropdownOption: `${Base_URL}/v1/ap/erp-integration/passthrough-tax-solutions`,
  },

  categorisation: {
    saveCategorisationToggleData: (page_name) =>
      `${API_ENDPOINT}/organizations/save-categorisations-toggle-data${
        page_name ? `?page_name=${page_name}` : ""
      }`,
    getCategorisationToggleData: (page_name, orgId) =>
      `${API_ENDPOINT}/organizations/get-categorisations-toggle-data/${orgId}${
        page_name ? `?page_name=${page_name}` : ""
      }`,
    getCategorisationToggleDataV2: () =>
      `${Base_URL}/v2/organizations/custom-fields/get-categorisation-toggle-data`,
    updateCategorisationToggleV2: (integrationType) =>
      `${Base_URL}/v2/organizations/custom-fields/${integrationType}/toggle `,
    customExpenseList: (integrationType, field_visibility, attribute_type) =>
      `${Base_URL}/v2/organizations/custom-fields/${integrationType}`,
    getVatFieldDetails: (page_name) =>
      `${API_ENDPOINT}/organizations/vat-validation-data?page_name=${page_name} `,
    updateVatFieldDetails: `${API_ENDPOINT}/organizations/vat-validation-data`,
    customFieldsOptions: (activeKey) =>
      `${API_ENDPOINT}/organizations/list-custom-field-options/${activeKey}`,
    getFieldDetails: (page_name) =>
      `${API_ENDPOINT}/organizations/required-fields-data?page_name=${page_name} `,
    updateFieldDetails: `${API_ENDPOINT}/organizations/required-fields-data`,
    getConditionalList: ({ integrationType, field_visibility }) =>
      `${Base_URL}/v2/organizations/custom-fields/${integrationType}?field_visibility=${field_visibility}&statusCode=200`,
    defaultConditionalSequence: ({ integrationType }) =>
      `${Base_URL}/v2/organizations/custom-fields/sorting/${integrationType}`,
    getERPCategorisation: `${API_ENDPOINT}/organizations/entity-wise-line-item/erp-details`,
  },
  dashboard: {
    PODashboard: `${API_ENDPOINT}/purchase-orders/pocount`,
    IMDashboard: `${API_ENDPOINT}/ap/imcount`,
    cardAndCardRequestDashboard: (org_id, user_org_id) =>
      `${API_ENDPOINT}/cards/getCardsCount/${org_id}?user_org_id=${user_org_id}`,
    cardExpensesDashboard: `${API_ENDPOINT}/card-expenses/getExpenseCount`,
  },
  emailAction: {
    sendRequest: `${API_ENDPOINT}/organizations/email-based-action`,
    verifyInvoiceStatus: `${API_ENDPOINT}/organizations/verify-email-based-action`,
    resendApprovalMail: `${Base_URL}/v3/smart-approval/resend-approval-mail`,
  },
  workflow: {
    createNewWorkflow: `${Base_URL}/v3/smart-approval/create-workflow`,
    updateWorkflow: `${Base_URL}/v3/smart-approval/edit-workflow`,
    updateWorkflowLambdaApi: (urlParams) =>
      `${PrimaryURL}${urlParams}?workflow_type=edit_workflow`,
    getWorkflowCategory: `${Base_URL}/v4/smart-approval/get-workflow-categories`,
  },
  paymentRuns: {
    create: `${Base_URL}/v1/ap/payment-run/create`,
    listing: `${API_ENDPOINT}/ap/payment-run/list`,
    approvedInvoicesListing: `${API_ENDPOINT}/ap/payment-run/invoice-list`,
    getUsersListForCreatePaymentRun: `${Base_URL}/v4/smart-approval/modulewise-users`,
    addUsersToCreatePaymentRunList: `${Base_URL}/v4/smart-approval/modulewise-users`,
    removeUserFromCreatePaymentRunList: (userId) =>
      `${Base_URL}/v4/smart-approval/modulewise-users/${userId}/status/deleted`,
    getUsersListForScheduleAndPay: `${Base_URL}/v3/smart-approval/payment-runs/pay-schedule`,
    addUserToScheduleAndPayList: `${Base_URL}/v3/smart-approval/payment-runs/pay-schedule`,
    removeUserFromScheduleAndPayList: (approverTypeId, workflowId, role) =>
      `${Base_URL}/v3/smart-approval/payment-runs/pay-schedule/${approverTypeId}/${workflowId}/${role}`,
    getAuditTrailHistory: `${Base_URL}/v3/smart-approval/audit-payment-runs-history/`,
    downloadPDF: (orderId) =>
      `${API_ENDPOINT}/ap/payment-run/invoicepdf/${orderId}`,
    downloadExcel: `${API_ENDPOINT}/ap/payment-run/download-excel`,
    downloadSinglePDFInPaid: (orderId) =>
      `${API_ENDPOINT}/ap/payment-run/paymentRunpdf/${orderId}`,
    markAsPaid: (paymentRunId) =>
      `${API_ENDPOINT}/ap/payment-run/payment-run-mark-as-paid/${paymentRunId}`,
    schedulePayment: `${API_ENDPOINT}/ap/payment-run/schedule-payment`,
    approveOrRejectPaymentRun: `${API_ENDPOINT}/ap/payment-run/status`,
    removeInvoice: `${Base_URL}/v2/ap/payment-run/remove-invoice`,
    platformUsersInSmartApprovals: `${Base_URL}/v4/smart-approval/modulewise-permissions-users`,
    downloadPRFile: `${API_ENDPOINT}/ap/download-bacs-file `,
    downloadPRFileLink: (fileName) =>
      `${API_ENDPOINT}/ap/download-bacs-file?filename=${fileName}`,
    validateBacsPayment: `${API_ENDPOINT}/ap/payment-run/bacs-download-file-payment`,
  },
  invoicePOMatching: {
    invoiceForMatching: `${API_ENDPOINT}/ap/invoice-po-match/list`,
    matchPOToInvoice: `${API_ENDPOINT}/ap/confirm-po-match`,
    unMatchPOToInvoice: `${API_ENDPOINT}/ap/unmatch-po-match`,
    audit: (invoiceId) =>
      `${API_ENDPOINT}/ap/audit-invoice-po-match/${invoiceId}`,
  },
  threshold: {
    getThreshold: (orgId) =>
      `${API_ENDPOINT}/ap/invoice-po-match/orgThreshold/${orgId}`,
    saveThreshold: `${API_ENDPOINT}/ap/invoice-po-match/orgThreshold`,
  },
  configurations: {
    getConfigList: `${API_ENDPOINT}/organizations/get-organization-config-flag`,
    getGrniLogged: `${API_ENDPOINT}/purchase-orders/is-grni-logged`,
    updateConfiguration: `${API_ENDPOINT}/organizations/set-organization-config-flag`,
    taxCode: {
      taxCodesList: (params = "") =>
        `${API_ENDPOINT}/organizations/org-config-fields${params}`,
      requiredTaxCode: ({ isPo }) =>
        `${API_ENDPOINT}/organizations/set-organization-${
          isPo ? "" : "invoice-"
        }tax-flag`,
    },
    entity: {
      list: (params = "") =>
        `${API_ENDPOINT}/organizations/org-config-entities${params}`,
    },
    lineItem: {
      list: ({ integrationType }) =>
        `${API_ENDPOINT}/organizations/line-item-toggle-state/${integrationType}/entities`,
      update: `${API_ENDPOINT}/organizations/entity-wise-line-item-mapping`,
    },
  },
  customConditionalField: {
    getCustomFieldValuesWithPagination: ({ integrationType, fieldId }) =>
      `${Base_URL}/v3/organizations/custom-fields/${integrationType}/${fieldId}/values`,
    getVisibiltyAndOptionList: ({ integrationType }) =>
      `${Base_URL}/v2/organizations/custom-fields/metadata/${integrationType}`,
    getCategorisationToggleData: () =>
      `${Base_URL}/v2/organizations/custom-fields/get-categorisation-toggle-data`,
    createCustomField: ({ integrationType }) =>
      `${Base_URL}/v2/organizations/custom-fields/${integrationType}`,
    updateCustomeField: ({ integrationType, customFieldId }) =>
      `${Base_URL}/v2/organizations/custom-fields/${integrationType}/${customFieldId}`,
    getCustomFieldDetailById: ({ integrationType, customFieldId }) =>
      `${Base_URL}/v2/organizations/custom-fields/${integrationType}/${customFieldId}`,
    deleteCustomExpense: ({ integrationType, customFieldId }) =>
      `${Base_URL}/v2/organizations/custom-fields/${integrationType}/${customFieldId}`,
    getCustomFields: ({ integrationType = "po" }) =>
      `${Base_URL}/v2/organizations/custom-fields/${integrationType}/all/with-saved-inputs`,
    getCustomFieldsOnlyDynamicAndPredefined: ({ integrationType }) =>
      `${Base_URL}/v2/organizations/custom-fields/${integrationType}?field_visibility=default&attribute_type=dynamic,predefined`,
    getCustomFieldValues: ({ id, integrationType }) =>
      `${Base_URL}/v2/organizations/custom-fields/${integrationType}/${id}`,
    getCustomFieldsByPOId: (id, lineItemIds) =>
      `${Base_URL}/v2/organizations/custom-fields/po/all/with-saved-inputs/${id}?line_item_ids=${lineItemIds}`,
  },
  smartApprovalRefactor: {
    getAllWorkflowList: (type, searchUrl) =>
      `${Base_URL}/v3/smart-approval/get-all-workflows?type=${type}${searchUrl}`,
    getModuleWiseCreatorsList: (module) =>
      `${Base_URL}/v4/smart-approval/modulewise-users?module=${module}`,
  },
  integrations: {
    linkToken: `${Base_URL}/erp/linktoken/`,
    sendlinkToken: (linkToken) =>
      `${API_ENDPOINT}/accounting-integrations/erp-linktoken?link_token=${linkToken}`,
    getConnectionStatus: `${API_ENDPOINT}/accounting-integrations/erp-linktoken`,
    getSyncedData: `${Base_URL}/erp/sync-data/proxy-sync/`,
    getEntityMappingList: (linkTokenId) =>
      `${API_ENDPOINT}/organizations/erp-entity-company-mapping?link_token_id=${linkTokenId}`,
    getEntityMappingListByOrgId: (orgId) =>
      `${API_ENDPOINT}/organizations/erp-entity-company-mapping?org_id=${orgId}`,
    getErpCompanies: (linkTokenId) =>
      `${API_ENDPOINT}/organizations/erp-companies?link_token_id=${linkTokenId}`,
    saveEntityCompanyMapping: `${API_ENDPOINT}/organizations/erp-entity-company-mapping`,
    updateEntityCompanyMapping: (
      id,
      linkTokenId,
      entityId,
      erpCompanyId,
      status
    ) =>
      `${API_ENDPOINT}/organizations/erp-entity-company-mapping?id=${id}&erp_link_token_id=${linkTokenId}&entity_id=${entityId}&erp_company_id=${erpCompanyId}&status=${status}`,
    getErpConfigMapping: (linkTokenId) =>
      `${API_ENDPOINT}/accounting-integrations/erp_config_mapping?link_token_id=${linkTokenId}`,
    updateErpConfigMapping: (id, status, linkTokenId) =>
      `${API_ENDPOINT}/accounting-integrations/erp_config_mapping/status?id=${id}&link_token_id=${linkTokenId}&status=${status}`,
    getMasterDataList: (linkTokenId) =>
      `${API_ENDPOINT}/accounting-integrations/erp-sync-details?link_token_id=${linkTokenId}`,
    disconnectErp: (linkTokenId) =>
      `${API_ENDPOINT}/accounting-integrations/erp-disconnect/${linkTokenId}`,
    checkEntityMappingStatus: (entityId) =>
      `${API_ENDPOINT}/accounting-integrations/check_erp_config_mapping?entity_id=${entityId}`,
    getErpAccounts: (entityId) =>
      `${API_ENDPOINT}/organizations/erp_accounts?entity_id=${entityId}`,
    getErpItemLists: (entityId) =>
      `${API_ENDPOINT}/ap/erp-integration/erp-items/${entityId}`,
    getTrackingCatgories: (entityId) =>
      `${API_ENDPOINT}/organizations/erp-tracking-categories?entity_id=${entityId}`,
    getTaxRateList: (entityId, classification) =>
      `${API_ENDPOINT}/organizations/erp-tax-rates?entity_id=${entityId}`,
    getAllTaxRateList: (entityId) =>
      `${API_ENDPOINT}/organizations/entity-wise/erp-tax-rate-list?entity_id=${entityId}`,
    deleteUser: `${Base_URL}/erp/delete-info/deleteusr/`,
    workflowAutomationUnmappingForEntity: `${Base_URL}/v1/organizations/workflow-automation-unmapping-for-entity`,
    getERPCompanies: `${API_ENDPOINT}/organizations/get-organization-list-for-integration`,
    createERPContact: `${API_ENDPOINT}/organizations/create-supplier-on-xero`,
  },
  credit_notes: {
    creditNote: `${Base_URL}/v1/credit-notes`,
    ocrUpload: `${API_ENDPOINT}/ap/ocr-receipts-upload?is_credit_note=true`,
    getInvoicesBySupplierId: `${Base_URL}/v1/ap/suppliers/invoices`,
    downloadExcel: `${Base_URL}/v1/credit-notes/download`,
    approve: `${Base_URL}/v3/smart-approval/approved/updateStatus`,
    reject: `${Base_URL}/v3/smart-approval/rejected/updateStatus`,
    assignToWorkflow: `${Base_URL}/v3/smart-approval/assign-workflow`,
    applyRemoveCreditNote: `${Base_URL}/v1/credit-notes/apply-remove`,
    getCreditNotesBySupplierId: ({ invoiceId }) =>
      `${Base_URL}/v1/ap/${invoiceId}/credit-notes`,
    getCreditNoteDetails: ({ creditNoteId }) =>
      `${Base_URL}/v1/ap/${creditNoteId}/credit-note-details`,
    getSuggestedInvoicesByCreditNoteId: ({ creditNoteId }) =>
      `${Base_URL}/v1/ap/${creditNoteId}/get-invoices/unapplied`,
    getInvoicesByCreditNoteId: ({ creditNoteId }) =>
      `${Base_URL}/v1/ap/${creditNoteId}/get-invoices/applied?perPageCount=1000`,
    auditLog: `${API_ENDPOINT}/credit_notes/credit_note-audit-history`,
    postComment: `${API_ENDPOINT}/credit_notes/audit-log-comments`,
  },
  getWorkflowSteps: ({ type }) =>
    `${Base_URL}/v3/smart-approval/multi-steps/${type}`,
};

import {
  CLIENT_ID,
  CLIENT_SECRET,
  SSO_CLIENT_ID,
  SSO_CLIENT_SECRET,
} from "../../redux/service/apiConstant";
import { LOGIN_FORM, OTP_FORM, PAYMENT_JOURNEY_FORM } from "../../utils";
import { NAVIGATION_URL } from "../navigationUrl";
import { ACCENTS, BUTTON_VARIANT } from "../theme";

export const GRANT_TYPE = {
  password: "password",
  refreshToken: "refresh_token",
  selfRegistration: "self-registration",
  createCard: "Create_card",
  cardMonthlyLimit: "card_monthly_limit",
  profileUpdate: "profile-update",
};

export const OTP_TYPE = {
  login: "login",
};

export const LOGIN_SECTION = {
  CHECK_EMAIL: "CHECK_EMAIL",
  LOGIN_FORM: "LOGIN_FORM",
  OTP_FORM: "OTP_FORM",
  PROFILE_SELECTION_FORM: "PROFILE_SELECTION_FORM",
};

export const LOGIN_CONSTANTS = {
  welcomeTitle: "Welcome to Kloo!",

  presetPayload: {
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    scope: "*",
  },

  LOGIN_FORM: {
    email: {
      placeholder: `Email address`,
      label: `Email address`,
      name: "username",
      size: "large",
      rules: LOGIN_FORM.EMAIL,
    },
    password: {
      placeholder: `Password`,
      label: `Password`,
      name: "password",
      type: "password",
      size: "large",
      isPassword: true,
      rules: LOGIN_FORM.PASSWORD,
    },
    loginButton: {
      text: "Log in",
      htmlType: "submit",
      className: "w-100",
      customSize: "large",
      accent: ACCENTS.PRIMARY,
      variant: BUTTON_VARIANT.BUTTON_FILLED,
    },
    nextButton: {
      text: "Next",
      htmlType: "submit",
      className: "w-100",
      customSize: "large",
      accent: ACCENTS.PRIMARY,
      variant: BUTTON_VARIANT.BUTTON_FILLED,
    },
  },
  microsoftButton: {
    text: "Sign in with Microsoft",
    className: "w-100",
    customSize: "large",
    accent: ACCENTS.PRIMARY,
    variant: BUTTON_VARIANT.BUTTON_FILLED,
  },
  FORGOT_PASSWORD: {
    text: "Forgot password?",
    link: NAVIGATION_URL.FORGOT_PASSWORD,
  },
  TROUBLE_MESSAGE: {
    tooltip: {
      iconName: "info",
      message:
        "As your organisation has SSO enabled, you will need to reset your password via Microsoft. Simply visit the Microsoft login page, click on forgot password and follow the steps to get a new password.",
    },
    text: "Having trouble logging in?",
  },
  SSO_DIVIDER: {
    text: "Or sign in with:",
    variant: "body",
    fontStyle: "semibold",
    responsiveFontSize: false,
  },
  SIGN_UP: {
    text: "Don't have an account?",
    label: "Sign up",
    link: "https://www.getkloo.com/",
  },
  MICROSOFT_CONSTANTS: {
    presetPayload: {
      grant_type: GRANT_TYPE.password,
      client_id: SSO_CLIENT_ID,
      client_secret: SSO_CLIENT_SECRET,
      scope: "*",
      is_sso_validated: true,
    },
  },
  emailNotMatchError: "Email does not match",
  error: {
    title: "Login error!",
    subtitle: "Your email address or password is incorrect",
  },
};

export const OTP_CONSTANTS = {
  verificationCode: {
    name: "otp",
    validateTrigger: "onChange",
    type: "text",
    placeholder: "Enter code",
    size: "large",
    autoComplete: "off",
  },
  otpSentMessage: "OTP sent via SMS",
  mobileNumberMessage:
    "We have sent a verification code to your mobile number ending with",
  timerTextMessage: "It may take a minute to receive your code.",
  verify: {
    label: "Verify",
  },
  resendButton: {
    label: "Resend a new code",
  },
  errorMessage: {
    title: "Login failed!",
    subTitle: "OTP is invalid",
  },
  recoveryErrorMessage: {
    title: "Login failed!",
    subTitle: "Recovery Code is invalid"
  },

};

export const REDIRECTION_CONST = {
  redirectTo: "redirect-to",
};
